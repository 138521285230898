import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { AuthService } from './auth.service';
import { Preferences } from '@capacitor/preferences';
import { StorageService } from './storage.service';
import { from, Observable, of } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { Platform, ToastController } from '@ionic/angular';
import { SipService } from './sip.service';
import { takeUntil } from 'rxjs/operators';
import { BaseComponent } from '../base/base.component';
import { StorageKey } from '../enums/storage-key.enum';
import { IntercomCallLogResponse } from '../_models/IntercomCallLogInterface';
import { EntryDevices, EntryDevicesResponse } from '../_models/IntercomListResponseInterface';
import { IntercomListItem } from '../_models/IntercomListItemInterface';
import { OpenDoorResponse } from '../_models/OpenDoorResponseInterface';
import { IntercomAuth } from '../_models/IntercomAuthInterface';
import { INativeStorageError } from '../_models/INativeStorageErrorInterface';
import { OpenUrl } from '../_models/IntercomOpenUrlInterface';
import { IntercomStatus } from '../_models/IntercomStatusInterface';
import { TokensResponse } from '../_models/IntercomTokensResponseInterface';
import { AccountType } from '../enums/account-type.enum';
import { UrlInfo } from '../_models/UrlInfoInterface';
import { LinkedPhoneNumbersResponse } from '../_models/LinkedPhoneNumbersResponseInterface';
import { PaymentsList } from '../_models/PaymentInfoInterface';
import { EntryDevicesCountService } from './intercom-count.service';
import { DoorphoneActivate } from '../_models/DoorphoneActivateInterface';
import { GateItem } from '../_models/GateItemInterface';
@Injectable({
  providedIn: 'root',
})
export class DoorphoneService extends BaseComponent {
  protected paidData: Map<number, boolean> = null;

  protected _paidWarning = true;

  protected refresh = true;

  private idsToSort = null;

  constructor(
    private http: HttpClient,
    private authService: AuthService,
    private storage: StorageService,
    private platform: Platform,
    private sip: SipService,
    private entryDevicesCountService: EntryDevicesCountService,
    private toastController: ToastController,
  ) {
    super();
    this.paidData = new Map<number, boolean>();
  }

  getToken(): void {
    this.http
      .post('main://authIntercom', {})
      .pipe(takeUntil(this.ngOnDestroy$))
      .subscribe((res: IntercomAuth) => {
        this.authService.login(res.token, { prefix: AccountType.Intercom } as UrlInfo);
      });
  }

  loadDoorphones(): Observable<EntryDevicesResponse> {
    return this.http.get<EntryDevicesResponse>(
      'doorphone://client/doorphone',
      {},
    );
  }

  /**
   * Receiving information about calls.
   * @param id id intercom
   * @param query request parameters
   */
  loadCallLog(id, query = ''): Observable<IntercomCallLogResponse> {
    return this.http.get<IntercomCallLogResponse>(`doorphone://doorphone/${id}/call-log-list${query}`, {
      headers: {
        'Cache-Control': 'no-cache',
      },
    });
  }

  /**
   * deletes intercom and barrier data from storage
   */
  async resetCache(): Promise<void> {
    this.platform.ready().then(async () => {
      if (this.platform.is('cordova')) {
        await Preferences.set({
          key: StorageKey.EntryDevicesKey,
          value: JSON.stringify([])
        });
      } else {
        await this.storage.set(StorageKey.EntryDevicesKey, []);
      }

      await this.storage.set(StorageKey.IntercomPending, []);

      await this.storage.set(StorageKey.SipSettings, []).then(() => {});
    });
  }

  /**
   * reads data from storage, writes payment statuses, sip settings
   * @returns - doorphone data or empty array
   */
  getCache(): Observable<EntryDevices> {
    if (this.platform.is('cordova')) {
      return new Observable(subscriber => {
        this.platform.ready().then(() => {
          Preferences
            .get({ key: StorageKey.EntryDevicesKey })
            .then(({ value }) => {
              let val = JSON.parse(value) as EntryDevices;

              if (val && val?.doorphones instanceof Array) {
                this.setPaidData(val?.doorphones);
                subscriber.next(val);
              } else {
                subscriber.next(null);
              }

              subscriber.complete();
            })
            .catch((err: INativeStorageError) => {
              subscriber.next(null);
              subscriber.complete();
            });
        });
      });
    } else {
      return new Observable(subscriber => {
        this.platform.ready().then(() => {
          this.storage.get(StorageKey.EntryDevicesKey).then((val: EntryDevices) => {
            if (val && val?.doorphones instanceof Array) {
              this.setPaidData(val?.doorphones);
              subscriber.next(val);
            } else {
              subscriber.next(null);
            }
            subscriber.complete();
          });
        });
      });
    }
  }

  /**
   * saves the payment status of intercoms, sip settings. Writes data to storage
   * @param doorphones - list of data on intercoms
   */
  setCache(items: EntryDevices, register: Boolean = false): void {
    this.setPaidData(items?.doorphones);

    this.refreshTokens(items?.doorphones);

    this.entryDevicesCountService.setIntercomIds(items?.doorphones.map(doorphone => doorphone.id));
    this.entryDevicesCountService.setEntryDevicesCount(items?.barriers?.length + items?.doorphones?.length + items?.gates?.length);

    this.platform.ready().then(() => {
      if (this.platform.is('cordova')) {
        Preferences.set({
          key: StorageKey.EntryDevicesKey,
          value: JSON.stringify(items),
        });
      } else {
        this.storage.set(StorageKey.EntryDevicesKey, items);
      }

      register && this.sip.register();
    });
  }

  /**
   * updates data on intercoms and writes to storage
   * @param doorphone list of intercoms
   */
  async updateCache(doorphone: IntercomListItem): Promise<void> {
    await this.platform.ready();

    this.getCache()
      .pipe(takeUntil(this.ngOnDestroy$))
      .subscribe((items: EntryDevices) => {
        items?.doorphones.forEach((element, index) => {
          if (element.id === doorphone.id) {
            items.doorphones[index] = doorphone;
          }
        });

        this.setCache(items);
      });
  }

  /**
   * if there is data in the storage and a overwriting is not needed, then it updates the tokens and returns data about the intercoms.
   * If there is no data or overwriting is required, then a request to receive data is executed
   * @param reload - overwrite flag of intercom data
   */
  getDoorphones(reload: boolean = false) {
    return from(this.getCache()).pipe(
      switchMap((cache: EntryDevices) => {
        if (!this.refresh && !reload && cache && cache?.doorphones instanceof Array && cache?.doorphones) {
          const response: EntryDevices = { doorphones: cache.doorphones, barriers: cache?.barriers, gates: cache?.gates } as EntryDevices;

          return of(response);
        } else {
          this.refresh = false;

          return this.loadDoorphones()
            .toPromise()
            .then(
              (loaded: EntryDevicesResponse) => {
                const gates = this.initGates(loaded?.gates, loaded?.doorphones);

                const result = {doorphones: loaded?.doorphones, barriers: loaded?.barriers, gates: gates} as EntryDevices;
                console.log('result', result);
                this.setCache(result, true);

                return result;
              },
              () => {
                return { doorphones: [], barriers: [], gates: []};
              },
            );
        }
      }),
    );
  }

  /**
   * returns information on the intercom by identifier
   * @param id doorphone identifier
   * @param reload if true, the list of intercoms will be updated. false by default.
   * @returns intercom
   */
  getDoorphone(id: number, reload = false): Observable<IntercomListItem> {
    return new Observable(subscriber => {
      this.getDoorphones(reload)
        .pipe(takeUntil(this.ngOnDestroy$))
        .subscribe((res: EntryDevicesResponse) => {
          let item: IntercomListItem = null;

          for (const i in res.doorphones) {
            if (res.doorphones[i].id === id) {
              item = res.doorphones[i];
              break;
            }
          }

          subscriber.next(item);
          subscriber.complete();
        });
    });
  }

  filterDoorphones(searchAddress: string): Observable<Array<IntercomListItem>> {
    return this.http
      .get<EntryDevicesResponse>('doorphone://doorphone-search?filter%5Baddress%5D=' + searchAddress, {
        observe: 'response',
        headers: {
          'Cache-Control': 'no-cache',
        },
      })
      .map((response: HttpResponse<EntryDevicesResponse>) => {
        return response.body.data;
      })
      .share();
  }

  unlockBarrier(url: string) {
    this.generateResponseMess(this.http.post(`doorphone://${url}`, {}));
  }

  unlockDoor(doorphone: number, door?: number) {
    let url = `doorphone://doorphone/${doorphone}/open-door`;

    if (door) {
      url += '/' + door;
    }
    this.generateResponseMess(this.http.post(url, {}));
  }

  generateResponseMess(res)  {
    res.pipe(takeUntil(this.ngOnDestroy$))
    .subscribe((res: OpenDoorResponse) => {
        const result = res?.status;
        const message = result ? 'Открыто' : 'Ошибка!';
        const toast: DoorphoneActivate = { result, message } as DoorphoneActivate;
        this.unlockResultToast(toast);
      },
      error => {
        const result = error?.ok;
        let message = '';
        switch(error.status) {
          case 429:
            message = 'Слишком много попыток';
          default:
            message = 'Ошибка!'
        }

        const toast: DoorphoneActivate = { result, message } as DoorphoneActivate;
        this.unlockResultToast(toast);
      }
    );
  }

  async unlockResultToast(res: DoorphoneActivate): Promise<void> {
    const toast = await this.toastController.create({
      message: res?.message,
      duration: 2000,
      color: res?.result ? 'success' : 'danger',
      translucent: false,
      position: 'top',
    });

    toast.present();
  }

  getStatus(): Observable<null | string> {
    return new Observable(subscriber => {
      if (this.platform.is('cordova')) {
        Preferences
          .get({ key: StorageKey.IntercomStatus })
          .then(({ value }) => {
            let val = JSON.parse(value) as IntercomStatus;

            subscriber.next(val.status);
            subscriber.complete();
          },
          error => {
            subscriber.next(null);
            subscriber.complete();
          },
        );
      } else {
        subscriber.next(null);
        subscriber.complete();
      }
    });
  }

  initGates(gates: Array<GateItem>, doorphones: Array<IntercomListItem>) {
    return gates.map((gate) => {
      if (gate.doorphones && gate.doorphones.length) {
        for (const index in gate.doorphones) {
          const id = gate.doorphones[index].id;

          const result = doorphones.filter((doorphone) => doorphone.id === id);

          if (result && result.length) {
            gate.doorphone_id = result[0].id;
            gate.paid = result[0].apartment_sip_settings.paid;

            return gate;
          }
        }
      }

      gate.doorphone_id = 0;

      return gate;
    });
  }

  async refreshTokens(intercoms: Array<IntercomListItem>): Promise<void> {
    await this.platform.ready();

    let oldUrls: Array<OpenUrl> = [];

    if (this.platform.is('cordova')) {
      const { value } = await Preferences.get({ key: StorageKey.IntercomUrls });
      oldUrls = JSON.parse(value);
    } else {
      oldUrls = await this.storage.get(StorageKey.IntercomUrls);
    }

    const ids: Array<number> = [];
    const oldTokens: Map<number, string> = new Map();

    if (oldUrls) {
      oldUrls.forEach(element => {
        if (element.door_open_url && element.door_open_url.match(/door-unlock/g)) {
          oldTokens.set(element.id, element.door_open_url);
        }
      });
    }

    intercoms.forEach((element, i) => {
      if (element && !oldTokens.has(element.id)) {
        ids.push(element.id);
      }
    });

    let tokens: Map<number, string> = new Map();

    if (ids.length) {
      tokens = await this.getDoorphoneTokens(ids);
    }

    const urls: Array<OpenUrl> = [];

    intercoms.forEach((element, i) => {
      if (element && element.id) {
        urls.push({
          id: element.id,
          sip_name: element?.apartment_sip_settings?.sip_name,
          door_open_url: tokens && tokens.has(element.id) ? tokens.get(element.id) : (oldTokens.has(element.id) ? oldTokens.get(element.id) : '')
        } as OpenUrl);
      }
    });

    if (this.platform.is('cordova')) {
      await Preferences.set({
        key: StorageKey.IntercomUrls,
        value: JSON.stringify(urls),
      });
    } else {
      await this.storage.set(StorageKey.IntercomUrls, urls);
    }
  }

  async getDoorphoneTokens(ids: Array<number>): Promise<Map<number, string>> {
    const result = await this.http
      .post<TokensResponse>('main://getUnlockTokens', {
        ids,
      })
      .toPromise();

    const tokens: Map<number, string> = new Map();

    for (const key in result.tokens) {
      tokens.set(parseInt(key, 10), result.tokens[key]);
    }

    return tokens;
  }

  /**
   * writes payment statuses to the storage
   * @param doorphones list of intercoms
   */
  setPaidData(doorphones: Array<IntercomListItem>): void {
    this.paidData.clear();

    doorphones.forEach(element => {
      this.paidData.set(
        element.id,
        element?.apartment_sip_settings
          ? element.apartment_sip_settings?.paid
          : false,
      );
    });
  }

  /**
   * returns payment status by doorphone ID
   * @param id doorphone identifier
   * @returns payment State
   */
  isPaid(id: number): boolean {
    if (this.paidData && this.paidData.has(id)) {
      return this.paidData.get(id);
    }

    return false;
  }

  setPaidWarning(flag: boolean, update: boolean = false): void {
    this._paidWarning = flag;

    if (update && this._paidWarning) {
      this.http
        .post('main://paidWarning', {})
        .pipe(takeUntil(this.ngOnDestroy$))
        .subscribe(result => {});
    }
  }

  get paidWarning(): boolean {
    return this._paidWarning;
  }

  /**
   * Returns a list of numbers associated with the intercom
   * @param id intercom id
   * @param apartment apartment number
   */
  loadLinkedPhoneNumbers(id: number, apartment: number): Observable<LinkedPhoneNumbersResponse> {
    return this.http.get<LinkedPhoneNumbersResponse>(`doorphone://doorphone/${id}/apartment/${apartment}`);
  }

  /**
   * Request for payment information
   * @param apartmentId apartment number
   */
  loadPaymentsInfo(apartmentId: number): Observable<PaymentsList> {
    return this.http.get<PaymentsList>(`main://paymentsInfo/${apartmentId}`);
  }

  setRefresh() {
    this.refresh = true;
  }

  async readIdsToSort() {
    await this.storage.get(StorageKey.IdsToSortList).then(data => {
      this.idsToSort = data;
    });
  }

  async setIdsToSort(idsToSort) {
    this.idsToSort = idsToSort;
    await this.storage.set(StorageKey.IdsToSortList, idsToSort);
  }

  get getIdsToSort() {
    return this.idsToSort;
  }
}
