export const environment = {
  production: true,
  preview: true,
  host: 'https://ul.camera',
  deepLink: 'https://www.evo73.ru/mobile',
  iviz: {
    apiUrl: 'https://api.vms.evo73.ru/v2',
    baseUrl: 'https://api.vms.evo73.ru',
    streamUrl: 'https://base.vms.evo73.ru',
  },
  lk: {
    apiUrl: 'https://api.app.evo73.ru/api/v1',
    api2Url: 'https://api.app.evo73.ru/api/v2',
    payform: 'https://api.app.evo73.ru/evoPayForm',
  },
  doorphone: {
    apiUrl: 'https://doorphone.app.evo73.ru/api',
  },
  intercom: {
    appId: 924566543835,
    apiUrl: 'http://doorphone.app.evo73.ru/api'
  },
  daso: {
    apiUrl: 'https://daso.app.evo73.ru/api/v2'
  },
  openmap: {
    map: 'https://navy.gorodcamer.ru/openmap/osm_tiles/{z}/{x}/{y}.png'
  },
};
