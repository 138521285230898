import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';

import { LkLoggedAuthGuard } from './lk/logged-auth-guard';
import { LkNotLoggedAuthGuard } from './lk/not-logged-auth-guard';
import { IvizLoggedAuthGuard } from './iviz/logged-auth-guard';
import { IvizNotLoggedAuthGuard } from './iviz/not-logged-auth-guard';
import { LoggedAuthGuard } from './guard/logged-auth-guard';
import { NotLoggedAuthGuard } from './guard/not-logged-auth-guard';
import { DasoLoggedAuthGuard } from './daso/guard/logged-auth-guard';
import { DasoNotLoggedAuthGuard } from './daso/guard/not-logged-auth-guard';
import { IsPaidGuard } from './intercom/is-paid-guard';
import { LoadTokensGuard } from './daso/guard/load-tokens.guard';

const routes: Routes = [
  { path: '', redirectTo: '/main', pathMatch: 'full' },
  {
    path: 'main',
    loadChildren: () => import('./pages/main/main.module').then(m => m.MainPageModule),
  },
  {
    path: 'tabs',
    loadChildren: () => import('./city-cameras/tabs/tabs.module').then(m => m.TabsPageModule),
  },
  {
    path: 'record-form',
    loadChildren: () => import('./pages/record-form/record-form.module').then(m => m.RecordFormPageModule),
  },
  {
    path: 'contacts',
    loadChildren: () => import('./pages/contacts/contacts.module').then(m => m.ContactsPageModule),
  },
  {
    path: 'menu',
    loadChildren: () => import('./pages/menu/menu.module').then(m => m.MenuPageModule),
  },
  {
    path: 'services',
    loadChildren: () => import('./pages/services/services.module').then(m => m.ServicesPageModule),
  },
  {
    path: 'about',
    loadChildren: () => import('./pages/about/about.module').then(m => m.AboutPageModule),
  },
  {
    path: 'settings',
    loadChildren: () => import('./pages/settings/settings.module').then(m => m.SettingsPageModule),
  },
  {
    path: 'requests',
    loadChildren: () => import('./pages/requests/requests.module').then(m => m.RequestsPageModule),
  },
  {
    path: 'news',
    loadChildren: () => import('./pages/news/news.module').then(m => m.NewsPageModule),
  },
  {
    path: 'offer/:id',
    loadChildren: () => import('./pages/offer/offer.module').then(m => m.OfferPageModule),
  },
  {
    path: 'page',
    loadChildren: () => import('./pages/page/page.module').then(m => m.PagePageModule),
  },
  {
    path: 'info',
    loadChildren: () => import('./pages/info/info.module').then(m => m.InfoPageModule),
  },
  // intercom
  {
    path: 'ic-login',
    loadChildren: () => import('./intercom/pages/login/login.module').then(m => m.LoginPageModule),
    canActivate: [NotLoggedAuthGuard],
  },
  {
    path: 'ic-home',
    children: [
      {
        path: 'home',
        loadChildren: () => import('./intercom/pages/home/home.module').then(m => m.HomePageModule),
        canActivate: [LoggedAuthGuard],
      },
      {
        path: 'assign',
        loadChildren: () => import('./intercom/pages/assign/assign.module').then(m => m.AssignPageModule),
        canActivate: [LoggedAuthGuard],
      },
      {
        path: 'doorphone/:id',
        loadChildren: () => import('./intercom/pages/doorphone/doorphone.module').then(m => m.DoorphonePageModule),
        canActivate: [LoggedAuthGuard],
      },
      {
        path: 'history/:id',
        loadChildren: () => import('./intercom/pages/history/history.module').then(m => m.HistoryPageModule),
        canActivate: [LoggedAuthGuard],
      },
      {
        path: 'player/:id',
        loadChildren: () =>
          import('./intercom/pages/doorphone-player/doorphone.player.module').then(m => m.DoorphonePlayerPageModule),
        canActivate: [LoggedAuthGuard, IsPaidGuard],
      },
      {
        path: 'settings/:id',
        loadChildren: () => import('./intercom/pages/settings/settings.module').then(m => m.SettingsPageModule),
        canActivate: [LoggedAuthGuard],
      },
      {
        path: 'account/:id',
        children: [
          {
            path: '',
            loadChildren: () => import('./intercom/pages/account/account.module').then(m => m.AccountPageModule),
            canActivate: [LoggedAuthGuard],
          },
          {
            path: 'payments',
            loadChildren: () => import('./pages/payments/payments.module').then(m => m.PaymentsPageModule),
            canActivate: [LoggedAuthGuard],
          },
          {
            path: 'autopayments',
            loadChildren: () => import('./pages/autopayments/autopayments.module').then(m => m.AutopaymentsPageModule),
            canActivate: [LoggedAuthGuard],
          },
          {
            path: 'cards',
            loadChildren: () => import('./pages/bank-card-management/bank-card-management.module').then(m => m.BankCardManagementPageModule),
            canActivate: [LoggedAuthGuard],
          },
        ],
      },
      {
        path: 'archive/:id',
        loadChildren: () => import('./intercom/pages/archive/archive.module').then(m => m.ArchivePageModule),
        canActivate: [LoggedAuthGuard, IsPaidGuard],
      },
    ],
  },
  // lk
  {
    path: 'lk-login',
    loadChildren: () => import('./lk/pages/login/login.module').then(m => m.LoginPageModule),
    canActivate: [LkNotLoggedAuthGuard],
  },
  {
    path: 'lk-home',
    children: [
      {
        path: '',
        loadChildren: () => import('./lk/pages/home/home.module').then(m => m.HomePageModule),
        canActivate: [LkLoggedAuthGuard],
      },
      {
        path: 'profile',
        children: [
          {
            path: '',
            loadChildren: () => import('./lk/pages/profile/profile.module').then(m => m.ProfilePageModule),
            canActivate: [LkLoggedAuthGuard],
          },
          {
            path: 'autopayments',
            loadChildren: () => import('./pages/autopayments/autopayments.module').then(m => m.AutopaymentsPageModule),
            canActivate: [LkLoggedAuthGuard],
          },
          {
            path: 'cards',
            loadChildren: () => import('./pages/bank-card-management/bank-card-management.module').then(m => m.BankCardManagementPageModule),
            canActivate: [LkLoggedAuthGuard],
          },
        ],
      },
      {
        path: 'package-of-services',
        children: [
          {
            path: '',
            loadChildren: () => import('./lk/pages/package-of-services/package-of-services.module').then(m => m.PackageOfServicesModule),
            canActivate: [LkLoggedAuthGuard],
          },
          {
            path: 'autopayments',
            loadChildren: () => import('./pages/autopayments/autopayments.module').then(m => m.AutopaymentsPageModule),
            canActivate: [LkLoggedAuthGuard],
          },
          {
            path: 'payments',
            loadChildren: () => import('./pages/payments/payments.module').then(m => m.PaymentsPageModule),
            canActivate: [LkLoggedAuthGuard],
          },
          {
            path: 'about-tariff',
            loadChildren: () => import('./lk/pages/about-tariff/about-tariff.module').then(m => m.AboutTariffModule ),
            canActivate: [LkLoggedAuthGuard],
          },
          {
            path: 'service-management',
            children: [
              {
                path: '',
                loadChildren: () => import('./lk/pages/service-management/service-management.module').then(m => m.ServiceManagementModule),
                canActivate: [LkLoggedAuthGuard],
              },
              {
                path: 'services',
                loadChildren: () => import('./lk/pages/services/services.module').then(m => m.ServicesModule),
                canActivate: [LkLoggedAuthGuard],
              },
            {
              path: 'tariff-plan-change',
              children: [
                {
                  path: '',
                  loadChildren: () => import('./lk/pages/tariff-plan-change/tariff-plan-change.module').then(m => m.TariffPlanChangeModule),
                  canActivate: [LkLoggedAuthGuard],
                },
                {
                  path: 'about-tariff',
                  loadChildren: () => import('./lk/pages/about-tariff/about-tariff.module').then(m => m.AboutTariffModule ),
                  canActivate: [LkLoggedAuthGuard],
                },
              ]
            },]
          },
        ],
      },
      {
        path: 'payments',
        loadChildren: () => import('./pages/payments/payments.module').then(m => m.PaymentsPageModule),
        canActivate: [LkLoggedAuthGuard],
      },
    ],
  },

  {
    path: 'iviz-login',
    loadChildren: () => import('./iviz/pages/login/login.module').then(m => m.LoginPageModule),
    canActivate: [IvizNotLoggedAuthGuard],
  },
  {
    path: 'iviz',
    loadChildren: () => import('./iviz/pages/iviz-tabs/iviz-tabs.module').then(m => m.IvizTabsPageModule),
    canActivate: [IvizLoggedAuthGuard],
  },

  {
    path: 'daso',
    loadChildren: () => import('./daso/pages/tabs/tabs.module').then(m => m.TabsPageModule),
  },
  {
    path: 'daso-login/:dasoId',
    loadChildren: () => import('./daso/pages/login/login.module').then(m => m.LoginPageModule),
    canActivate: [DasoNotLoggedAuthGuard],
  },
  {
    path: 'daso-info/:dasoId',
    loadChildren: () => import('./daso/pages/info/info.module').then(m => m.InfoPageModule),
    canActivate: [LoadTokensGuard],
  },
  {
    path: 'daso-iviz/:dasoId',
    loadChildren: () => import('./daso/pages/iviz-tabs/daso-iviz-tabs.module').then(m => m.DasoIvizTabsPageModule),
    canActivate: [DasoLoggedAuthGuard],
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })],
  exports: [RouterModule],
})
export class AppRoutingModule {}
